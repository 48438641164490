import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'login',
    component: () => import('../components/pages/HomeView/HomeView.vue'),
    meta: { auth: false },
  },
  {
    path: '/chat',
    name: 'chatBot',
    component: () => import('../components/pages/ChatBot/ChatBot.vue'),
    meta: { auth: true }, // This route requires authentication
  },
  {
    path: '/activity-log',
    name: 'activity-log',
    component: () => import('../components/pages/ChatBot/activity-log.vue'),
    meta: { auth: true }, // This route requires authentication
  },
  {
    path: '/activity-log/:id',
    name: 'activity-log-detail',
    component: () => import('../components/pages/ChatBot/activity-view.vue'),
    meta: { auth: true }, // This route requires authentication
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import('../components/pages/HomeView/HomeView.vue'),
    meta: { auth: false },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Navigation Guard
router.beforeEach((to, from, next) => {

  const token = localStorage.getItem('userToken'); // Check for token in localStorage

  // If the route requires authentication and no token exists, redirect to login
  if (to.meta.auth && !token) {
    next({ name: 'login' });
  } else {
    // Allow access to the route
    next();
  }
});

export default router;
